<template>
  <div class="w-full flex flex-col gap-y-4">
    <div class="w-full flex items-center gap-x-4">
      <div class="flex-1 border-[0.8px] border-b-gray-200/20"></div>
      <span class="text-white opacity-[0.87] py-8">
        {{ $t("or sign in with") }}
      </span>
      <div class="flex-1 border-[0.8px] border-b-gray-200/20"></div>
    </div>
    <div class="w-full flex flex-col gap-y-6">
      <button
        class="variant-secondary outlined rounded-full py-3 flex justify-center items-center gap-x-2"
        @click="appleLogin"
      >
        <Icon
          name="IconSocialApple"
          size="24"
        ></Icon>
        <span
          class="pt-0.5 text-button-big font-bold text-white opacity-[0.87] disabled:opacity-50"
        >
          {{ $t("Apple") }}
        </span>
      </button>
      <button
        class="variant-secondary outlined rounded-full py-3 flex justify-center items-center gap-x-2"
        @click="fbLogin"
      >
        <Icon
          name="IconSocialFacebookF"
          size="24"
        ></Icon>
        <span
          class="pt-0.5 text-button-big font-bold text-white opacity-[0.87] disabled:opacity-50"
        >
          {{ $t("Facebook") }}
        </span>
      </button>

      <div
        class="relative variant-secondary outlined rounded-full py-3 flex justify-center items-center gap-x-2"
      >
        <GoogleSignInButton
          id="google-signin"
          @success="handleLoginSuccess"
          @error="handleLoginError"
          class="absolute top-0 z-10 w-full flex justify-center items-center overflow-hidden"
          width="500"
          style="filter: opacity(0%)"
        ></GoogleSignInButton>
        <button class="flex gap-x-2">
          <Icon
            name="IconSocialGoogle"
            size="24"
          ></Icon>
          <span
            class="pt-0.5 text-button-big font-bold text-white opacity-[0.87] disabled:opacity-50"
          >
            {{ $t("Google") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { jwtDecode } from "jwt-decode";

import {
  decodeCredential,
  useTokenClient,
  GoogleSignInButton,
  useCodeClient,
} from "vue3-google-signin";
import { useSessionStore } from "@/stores/session";
const sessionStore = useSessionStore();
const i18n = useI18n();
const appleIDAuth = ref();
const gRef = ref();
const { openToast } = useToast();
const { t } = useI18n();

const emit = defineEmits(["on-login", "on-successful-sso"]);

const doGoogleLogin = () => {
  document.querySelector("#google-signin").click();
};
// fix google login only use xhr for cors .
const getGoogleUserInfo = (access_token) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();

    xhr.open("GET", `https://www.googleapis.com/oauth2/v3/userinfo`);
    xhr.setRequestHeader("Authorization", `Bearer ${access_token}`);
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300)
        resolve(JSON.parse(this.responseText));
      else resolve({ err: "404" });
    };
    xhr.send();
  });
};
// handle success event
const handleLoginSuccess = async (response) => {
  const { credential } = response;
  const { given_name, family_name, email } = decodeCredential(credential);

  const payload = {
    language_code: i18n.locale.value,
    model_external_id: "PC",
    token: credential,
    token_type: "google",
    given_name,
    family_name,
    username: email,
    serialNumber: sessionStore.identifiers?.serialNumber,
    terminalId: sessionStore.identifiers?.terminalName,
  };

  emit("on-successful-sso", payload);
};

// handle an error event
const handleLoginError = (e) => {
  console.error("Login failed", e);
};

const { isReady, login: googleLogin } = useTokenClient({
  onSuccess: handleLoginSuccess,
  onError: handleLoginError,
  // other options
});

const fbLogin = async () => {
  const runtimeConfig = useRuntimeConfig();
  const fbGraphApiVersion = "v18.0";
  await window.FB.init({
    appId: runtimeConfig.public.facebookAppId,
    xfbml: true,
    version: fbGraphApiVersion,
  });

  await FB.login(
    function (response) {
      if (response.authResponse) {
        FB.api("/me", { fields: "name, email" }, function (response) {
          FB.getLoginStatus(function (loginStatusResponse) {
            if (!response.email) {
              openToast({
                content: t("FBSignInButAccountHasNoEmail"),
                type: "error",
              });
              return;
            }
            emit("on-successful-sso", {
              ...response,
              ...loginStatusResponse,
              language_code: i18n.locale.value,
              model_external_id: "PC",
              username: response.email,
              token: loginStatusResponse.authResponse.accessToken,
              token_type: "facebook",
            });
          });
        });
      } else {
        //  If you are not logged in, the login dialog will open for you to login asking for permission to get your public profile and email
        console.log("User cancelled login or did not fully authorize.");
      }
    },
    { scope: "public_profile,email" }
  );
};

// apple
const appleLogin = async () => {
  console.info("[🍎] start sso");

  try {
    const data = await appleIDAuth.value.signIn();
    console.log("appleIDAuth response", { appleIDAuth });
    const decoded =
      data.authorization.id_token && jwtDecode(data.authorization.id_token);

    console.log("appleIDAuth decoded", { decoded });
    emit("on-successful-sso", {
      language_code: i18n.locale.value,
      model_external_id: "PC",
      username: decoded.email,
      token: data.authorization.id_token,
      token_type: "apple",
    });
  } catch (error) {
    // Handle error.
    console.error(error);
  }
};
const appleSigninInit = () => {
  const runtimeConfig = useRuntimeConfig();
  appleIDAuth.value = AppleID.auth;
  appleIDAuth.value.init({
    clientId: runtimeConfig.public.appleId,
    scope: "name email",
    redirectURI: runtimeConfig.public.appleSSORedirectUrl,
    state: "initial",
    usePopup: true,
  });

  // Listen for authorization success.
  document.addEventListener("AppleIDSignInOnSuccess", (event) => {
    // Handle successful response.
    console.log("AppleIDSignInOnSuccess", event.detail.data);
  });

  // Listen for authorization failures.
  document.addEventListener("AppleIDSignInOnFailure", (event) => {
    // Handle error.
    console.log("AppleIDSignInOnFailure", event.detail.error);
  });
};

onMounted(() => {
  appleSigninInit();
});
</script>

<style lang="scss" scoped></style>
